import { clsx, type ClassValue } from "clsx"
import Sqids from "sqids"
import { twMerge } from "tailwind-merge"
import z from "zod"
import { addMinutes, addHours, addDays } from 'date-fns';


const sqids = new Sqids({
  alphabet: "FxnXM1kBN6cuhsAvjW3Co7l2RePyY8DwaU04Tzt9fHQrqSVKdpimLGIJOgb5ZE",
  minLength: 10,
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const logger = console

export const addPeriod = (period: string, date: Date): Date => {
  const [amountStr, unit] = period.split(' ');
  const amount = parseInt(amountStr, 10);

  switch (unit) {
      case 'minute':
      case 'minutes':
          return addMinutes(date, amount);
      case 'hour':
      case 'hours':
          return addHours(date, amount);
      case 'day':
      case 'days':
          return addDays(date, amount);
      // Add more cases for other units like weeks, months, etc.
      default:
          throw new Error(`Unsupported time unit: ${unit}`);
  }
};

export function fastHash(str:string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString(16);
}

export const formatCurrency = (amount: number) =>
  new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(amount)

export const uniqueArray = (arr: string[]) => {
  const res: Record<string, string> = {}
  arr.map((v) => (res[v] = v))
  return Object.keys(res)
}

export const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return []
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
    return zodKeys(schema.unwrap())
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element)
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape)
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested =
        value instanceof z.ZodType
          ? zodKeys(value).map((subKey) => `${key}.${subKey}`)
          : []
      // return nested keys
      return nested.length ? nested : key
    })
  }
  // return empty array
  return []
}

export const idToSqid = (id: string | number) => {
  return sqids.encode([typeof id === "string" ? parseInt(id) : id])
}

export const sqidToId = (sqid: string) => {
  return `${sqids.decode(sqid)[0]}`
}




export const SmCache = async <T, A extends any[]>(func: (...args: A) => Promise<T>, ...funcArgs: A): Promise<T> => {
  return await func(...funcArgs);
};



export function sortScheduleByDayOfWeek(schedule: {day:string}[]) {
  const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];
  
  // Sort schedule array based on the index of each day in daysOrder array
  schedule.sort((a, b) => {
      return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  });

  return schedule;
}
export const updateUserAllowed = ['pendingProfilePic', 'safeExitUrl', 'nickname', 'gender', 'category', 'affiliateReferralCodeId', 'healthCertLink', 'firstName', 'lastName', 'dob', 'state', 'pin', 'location', 'smsTemplate', 'enableSmsNotifications']
export const updateSellerAllowed = ['bankAccountName', 'bankAccount', 'bankBsb', 'bankName', 'registeredGst', 'abn', 'acceptsBank', 'acceptsCash', 'name', 'header', 'desc', 'suburb']
export const updateEscortAllowed = ['verified','datazooVerification','datazooUrl', 'age', 'location', 'nickname', 'gender', 'category', 'affiliateReferralCodeId', 'healthCertLink']
export const updateCustomerAllowed = ['favouriteProfiles','affiliateReferralCodeId', 'healthCertLink','datazooVerification','verified','datazooUrl']
